import React from "react";
import ExperienceCard from "./ExperienceCard";

export default function Projects({id}) {
    let experiences = [
        {
            id: 6,
            title: "Google LLC",
            location: "Irvine, USA",
            position: "Software Engineer",
            description: [
                "Design, test, monitor, maintain, and improve software applications and products in collaboration with cross-functional teams.",
                "Drive Google software development through the entire software development lifecycle, from design to implementation."
            ],
            timeline: "October 2021 to Present"
        },
        {
            id: 5,
            title: "Convrrt Inc.",
            location: "Los Angeles, USA",
            position: "Software Developer",
            description: [
                "Responsible for designing and implementing Admin Dashboard, resulted in reducing onboarding process by 10 times.",
                "Developing and upgrading already existing features for the platform that will improve performance of builder by 35%."
            ],
            timeline: "July 2021 to October 2021"
        },
        {
            id: 4,
            title: "University of Southern California",
            location: "Los Angeles, USA",
            position: "Course Producer",
            description: [
                "Managed team of 11 Course Producers for class assessments, assignments, conducting quizzes doubt solving sessions." +
                "Developed responsive web application using Bootstrap, JavaScript, TypeScript, Angular 10, Express and NodeJS." +
                "Tutored students during office hours to help them better understand foundational principles and advanced concepts."
            ],
            timeline: "August 2020 to May 2021"
        },
        {
            id: 3,
            title: "CoSAFE",
            location: "Boston, USA",
            position: "Software Development Intern",
            description: [
                "Lead a team of application developers to develop a progressive web application in React with Redux data store." +
                "Designed NoSQL databases & integrated Google Maps API to display Safety Score of restaurants against diseases."
            ],
            timeline: "June 2020 to September 2020"
        },
        {
            id: 2,
            title: "Aspirebit Technolab",
            location: "Surat, India",
            position: "Software Development Intern",
            description: [
                "Developed a scalable web application and database for Video Status Application in PHP and MySQL, respectively.",
                "Created API in PHP for Mobile Applications to retrieve and update data: acted as team leader of the project."
            ],
            timeline: "December 2018 to June 2019"
        },
        {
            id: 1,
            title: "Aspirebit Technolab",
            location: "Surat, India",
            position: "Web Development Intern",
            description: [
                "Managed databases in MySQL and analyzed customers’ feedbacks to provide insights to marketing team.",
                "Redesigned web application by extracting insightful data from customers’ feedback, results in increment of new users."
            ],
            timeline: "May 2018 to August 2018"
        }
    ]

    return (
        <div className="mb-sm-5 mb-lg-0 pt-5">
            <div id={id} className="d-flex min-vh-100 ">
                <div className="container-fluid text-center my-auto">
                    <h2>Work Experience</h2>
                    <br/>
                    <div className="row">
                        {experiences && experiences.map((experience) => (
                            <div className="col-12" key={experience.id}>
                                <ExperienceCard experience={experience}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
// Google Experience.
// Develop industry leading software specifically designed to meet the needs of Google's users,
// including next generation technologies and innovative applications to organize and make universally
// accessible the world's information utilizing scripting languages such as Java, C/C++, C#, Objective C,
// Python, JavaScript, or Go; design the architecture and components of Google computer applications
// and systems, as well as how those components interact utilizing technical approaches such as,
// object oriented analysis and design; design and develop massively scalable, distributed
// Google software applications and systems, that handle huge amounts of data and rapidly increasing
// user populations; drive Google software development through the entire software development lifecycle,
// from design to implementation; design, develop, implement and optimize advanced algorithms for Google
// products or services; document all technical aspects of the software and technology for future
// maintenance and enhancement; and design, test, monitor, maintain, and improve Google software
// applications and products in collaboration with cross-functional Google teams.
