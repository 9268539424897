import React from "react";
import SocialBar from "./SocialBar";

export default function ContactMe({id}) {
    return (
        <div className="mb-sm-5 mb-lg-0 mt-sm-5 mt-md-5 mt-lg-n5">
            <div id={id} className="d-flex  min-vh-100 mt-lg-n5">
                <div className="container-fluid text-center my-auto">
                    <div className="row">
                        <div className="col-md-1 col-lg-2"/>
                        <div className="col-sm-12 col-md-10 col-lg-8">
                            <h2>Get in Touch!</h2>
                            <a href="mailto:yashchanchad@gmail.com">
                                <button className="btn amber accent-4  mx-4 my-5"> Email</button>
                            </a>
                            <a href="https://resume.yashchanchad.com" download="Resume_YashChanchad" target="_blank" rel="noreferrer">
                                <button className="btn amber accent-4 mx-4 my-5"> Resume</button>
                            </a>
                        </div>
                        <div className="col-md-1 col-lg-2"/>
                    </div>
                    <div className="row">
                        <div className="col-md-1 col-lg-2"/>
                        <div className="col-sm-12 col-md-10 col-lg-8">
                            <SocialBar/>
                        </div>
                        <div className="col-md-1 col-lg-2"/>
                    </div>
                </div>
            </div>
        </div>
    );
}